import { HStack } from '@chakra-ui/react';
import React from 'react'
import { StlViewer } from 'react-stl-viewer';

function Render3DTap() {
  const url = "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"
  const tap_url = "https://peppertools.s3.sa-east-1.amazonaws.com/INCH-HAND-0.25-28-60-3F-D2.stl";
  const style = {
    top: 100,
    left: 0,
    width: '50vw',
    height: '50vh',
}
  return (
    <> 
    {/* <StlViewer
            style={style}
            orbitControls
            shadows
            url={url}
        /> */}
        <StlViewer
            style={style}
            orbitControls
            shadows
            url={tap_url}
        />
        </>
    
  )
}

export default Render3DTap
