import { Grid, GridItem, Tabs, TabList, Tab, TabPanels, TabPanel, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import GetQuote from '../GetQuote'
import { JobDetails } from '../JobDetails'
import Render3DTap from '../Render3DTap'
import { TapDesign } from '../TapDesign'

function MainTabs() {
    const [tabIndex, setTabIndex] = useState(0)
    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }
  return (
//     <Grid
//   templateAreas={`"header header"
//                   "nav main"
//                   "nav footer"`}
//   gridTemplateRows={'50px 1fr 30px'}
//   gridTemplateColumns={'150px 1fr'}
//   h='200px'
//   gap='1'
//   color='blackAlpha.700'
//   fontWeight='bold'
// >

  <Tabs index={tabIndex} onChange={handleTabsChange}>
  <TabList>
  
    <Tab>Job Details</Tab>
    <Tab>Tab Design</Tab>
    <Tab>Get Quote</Tab>
  </TabList>

  <TabPanels>
    
    <TabPanel>
       <HStack> 
    {/* @ts-ignore */}
      <Render3DTap nextAction={setTabIndex} />
     {/* @ts-ignore */}
      <JobDetails nextAction={setTabIndex}/>
    
    </HStack>
    </TabPanel>
    <TabPanel>
    <HStack> 
   {/* @ts-ignore */}
   <Render3DTap/>
   {/* @ts-ignore */}
   <TapDesign nextAction={setTabIndex}/>
 
 </HStack>
  
    </TabPanel>
    <TabPanel>
    <HStack> 
   
   <Render3DTap/>

   <GetQuote/>
 
 </HStack>
   
    </TabPanel>
  </TabPanels>
</Tabs>
  
  )
}

export default MainTabs