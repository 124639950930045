import { FormControl, FormLabel, Input, FormHelperText, Button, Select, HStack, VStack, Box } from "@chakra-ui/react";
import React from "react";

import { useForm, Controller } from "react-hook-form"

type FormValues = {
  ThreadType: number,
  HoleType: string,
  TapOrientation: string,
  ThreadDirection: string,
  TappingDepth: {
    depth: number,
    measure: string
  },
  MaterialBeingTapped: string,
  MaterialHardness: {
    value: number,
    scale: string
  }
}

type JobDetailsProps = {
  nextAction: any
}

export const JobDetails = ({nextAction}: JobDetailsProps) => {
  const { handleSubmit, control } = useForm<FormValues>()

  return (
    <Box> 
    <VStack>
    <FormControl onSubmit={handleSubmit((data) => console.log(data))}>
      <Controller
        control={control}
        name="ThreadType"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select onChange={onChange} value={value} placeholder='Select Thread Type'>
            <option value='60'>60</option>
            <option value='45'>45</option>
            <option value='0'>Other</option>
          </Select>

        )}
      />

      <Controller
        control={control}
        name="HoleType"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select onChange={onChange} value={value} placeholder='Select Hole Type'>
            <option value='through'>through</option>
            <option value='blind'>blind</option>

          </Select>
        )}
      />

      <Controller
        control={control}
        name="TapOrientation"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select onChange={onChange} value={value} placeholder='Select Tap Orientation'>
            <option value='vertical'>vertical</option>
            <option value='horizontal'>horizontal</option>

          </Select>
        )}
      />

      <Controller
        control={control}
        name="ThreadDirection"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select onChange={onChange} value={value} placeholder='Select Thread Direction'>
            <option value='Left hand'>Left hand</option>
            <option value='Right hand'>Right hand</option>

          </Select>
        )}
      />

      <Controller
        control={control}
        name="TappingDepth"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <HStack>
            <Input onChange={onChange} value={value && value.depth} placeholder='Depth' />
            <Select onChange={onChange} value={value && value.measure} placeholder='Measure'>
              <option value='inch'>Inch</option>
              <option value='milimeter'>Milimeter</option>

            </Select></HStack>
        )}
      />

      <Controller
        control={control}
        name="MaterialBeingTapped"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select onChange={onChange} value={value} placeholder='Material Being Tapped'>
            <option value="UNKNOWN">UNKNOWN</option>
            <option value="ALUMINUM (WROUGHT)">ALUMINUM (WROUGHT)</option>
            <option value="ALUMINUM DIE CASTING">ALUMINUM DIE CASTING</option>
            <option value="ALUMINUM BRONZE">ALUMINUM BRONZE</option><option value="BAKELITE (HARD PLASTIC)">BAKELITE (HARD PLASTIC)</option>
            <option value="BERYLLIUM COPPER">BERYLLIUM COPPER</option>
            <option value="BRASS ">BRASS </option>
            <option value="BRONZE (FREE-MACHINE)">BRONZE (FREE-MACHINE)</option>
            <option value="CAST BRASS">CAST BRASS</option>
            <option value="CAST IRON (GRAY)">CAST IRON (GRAY)</option>
            <option value="COPPER">COPPER</option>
            <option value="COPPER - NICKEL">COPPER - NICKEL</option>
            <option value="DELRIN">DELRIN</option>
            <option value="DUCTILE IRON">DUCTILE IRON</option>
            <option value="DURALUMIN">DURALUMIN</option>
            <option value="FERRO-TIC">FERRO-TIC</option>
            <option value="FIBERGLASS">FIBERGLASS</option><option value="HASTELLOY">HASTELLOY</option><option value="INCONEL">INCONEL</option><option value="MAGNESIUM">MAGNESIUM</option><option value="MALLEABLE IRON">MALLEABLE IRON</option><option value="MANGANESE 0 ">MANGANESE 0 </option><option value="MANGANESE BRONZE">MANGANESE BRONZE</option><option value="MOLYBDENUM">MOLYBDENUM</option><option value="MONEL">MONEL</option><option value="NAVAL BRASS">NAVAL BRASS</option><option value="NAVAL BRONZE">NAVAL BRONZE</option><option value="NICKEL SILVER">NICKEL SILVER</option><option value="NICKEL (PURE)">NICKEL (PURE)</option><option value="NITRALLOY">NITRALLOY</option><option value="NITRONIC">NITRONIC</option><option value="NYLON">NYLON</option><option value="THERMOPLASTIC (SOFT)">THERMOPLASTIC (SOFT)</option><option value="THERMOSETTING (HARD)">THERMOSETTING (HARD)</option><option value="POWDERED METAL (Sintered)">POWDERED METAL (Sintered)</option><option value="&quot;RUBBER, HARD&quot;">"RUBBER, HARD"</option><option value="SILICON BRONZE">SILICON BRONZE</option><option value="CARBON STEEL">CARBON STEEL</option><option value="COLD-ROLLED STEEL">COLD-ROLLED STEEL</option><option value="FORGED STEEL">FORGED STEEL</option><option value="LEADED STEEL">LEADED STEEL</option><option value="FREE MACH. STAINLESS">FREE MACH. STAINLESS</option><option value="PRECIP. HARDENING STAINLESS">PRECIP. HARDENING STAINLESS</option><option value="TOOL STEEL">TOOL STEEL</option><option value="TITANIUM">TITANIUM</option><option value="TUNGSTEN">TUNGSTEN</option><option value="TURCITE (SOFT PLASTIC)">TURCITE (SOFT PLASTIC)</option><option value="ZAMAK (ZINC DIE CAST)">ZAMAK (ZINC DIE CAST)</option><option value="ZINC">ZINC</option>

          </Select>
        )}
      />

<Controller
        control={control}
        name="MaterialHardness"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <HStack>
            <Input onChange={onChange} value={value && value.value} placeholder='Hardness Value' />
            <Select onChange={onChange} value={value && value.scale} placeholder='Hardness Scale'>
              <option value='Brinnell'>Brinnell</option>
              <option value='Rockwell'>Rockwell</option>

            </Select></HStack>
        )}
      />

<Button onClick={()=>{
        // @ts-ignore
        nextAction(1)
        }}>Next</Button>
    </FormControl>
    </VStack>
    </Box>
  );
};
