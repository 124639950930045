import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  GridItem,
  HStack,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import { JobDetails } from "./components/JobDetails"
import { TapDesign } from "./components/TapDesign"
import GetQuote from "./components/GetQuote"
import Render3DTap from "./components/Render3DTap"
import MainTabs from "./components/MainTabs"

export const App = () => (
  <ChakraProvider theme={theme}>
   
    
        <ColorModeSwitcher justifySelf="flex-end" />

       <MainTabs/>
  </ChakraProvider>
)
