import { FormControl, HStack, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

type ContactFormValues = {
    first_name: string,
    last_name: string,
    company: string,
    email: string,
    phone: string,
    quantity: number,
    notes: string,
    rate: number
   
  }

const GetQuote = () => {
    const { handleSubmit, control } = useForm<ContactFormValues>();

    return (
        <VStack spacing={4} > 
    <FormControl onSubmit={handleSubmit((data) => console.log(data))}>
     <Controller
        control={control}
        name="first_name"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <HStack> 
           
            <Input
              value={value}
              onChange={onChange}
              placeholder='First Name'
              size='md'
            />
            </HStack>
        )}
      />

<Controller
        control={control}
        name="last_name"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <HStack> 
          
            <Input
              value={value}
              onChange={onChange}
              placeholder='Last Name'
              size='md'
            />
            </HStack>
        )}
      />

<Controller
        control={control}
        name="company"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <HStack> 
          
            <Input
              value={value}
              onChange={onChange}
              placeholder='Company'
              size='md'
            />
            </HStack>
        )}
      />

<Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <HStack> 
          
            <Input
              value={value}
              onChange={onChange}
              placeholder='Email'
              size='md'
            />
            </HStack>
        )}
      />

<Controller
        control={control}
        name="phone"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <HStack> 
           
            <Input
              value={value}
              onChange={onChange}
              placeholder='Phone'
              size='md'
            />
            </HStack>
        )}
      />

<Controller
        control={control}
        name="quantity"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <HStack> 
          
          <NumberInput onChange={onChange} defaultValue={1} min={1} max={100}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            </HStack>
        )}
      />

    <Controller
        control={control}
        name="notes"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <HStack> 
       
            <Select onChange={onChange} value={value} placeholder='Rate your experience'>
          
					            
					            <option value="5">Very Good</option>
					            <option value="4">Good</option>
                                <option value="3">OK</option>
					            <option value="2">Bad</option>
                                <option value="1">Very Bad</option>
					      
            
           
          </Select>
            </HStack>
        )}
      />

<Controller
        control={control}
        name="rate"
        render={({ field: { onChange, onBlur, value, ref } }) => (
            <HStack> 
      
            <Input
              value={value}
              onChange={onChange}
              placeholder='Notes'
              size='md'
            />
            </HStack>
        )}
      />


     </FormControl>
     </VStack>
    )
 
  
}

export default GetQuote